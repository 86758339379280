<template>
  <div class="list-user admin_container_default">
    <div class="list-user-table px-0">
      <div class="mb-2 row p-0 admin_header">
        <div class="d-flex p-0 admin_title">
          <div class="text-truncate-mess header-font font-weight-bold">
            カート利用中のユーザー一覧
          </div>
        </div>
        <div class="tex-left text-md-right text-sm-right admin_right">
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listCartAdmin.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
        class="cpx-4"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + listCartAdmin.from }}</td>
        </template>
        <template v-slot:name="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.name }}
            </div>
          </td>
        </template>
        <template v-slot:email="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.email }}
            </div>
          </td>
        </template>
        <template v-slot:title="{ item }">
          <td style="" class="py-2">
            <div class="text-truncate-mess">
              {{ item.title }}
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCartAdmin.total"
      >
        <p class="font-weight-bold">
          {{ listCartAdmin.total }}件中{{ listCartAdmin.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listCartAdmin.last_page"
      >
        <CPagination
          v-if="listCartAdmin.total"
          :activePage.sync="page"
          :pages="listCartAdmin.last_page"
          align="center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ListCart",
  data() {
    return {
      fields: tableFields.ALL_CART,
      limit: Constants.LIMIT,
      page: 1,
      isLoading: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  created() {
    const request = {
      page: this.page,
      data: {
        limit: this.limit,
        shop_id: this.shop_id,
      },
    };
    this.getListCartAdmin(request);
  },
  computed: {
    ...mapGetters(["listCartAdmin", "noteById", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    page() {
      const request = {
        page: this.page,
        data: {
          limit: this.limit,
          shop_id: this.shop_id,
        },
      };
      this.getListCartAdmin(request);
      window.scrollTo(0, 0);
    },
    listCartAdmin() {
      this.isLoading = false;
    },
  },
  methods: {
    ...mapActions({
      getListCartAdmin: "getListCartAdmin",
    }),
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "DashboardAdminBox" });
      } else {
        this.$router.push({ name: "DashboardAdminBox domain" });
      }
    },
  },
};
</script>
<style>
.admin_header .admin_title {
  width: 60% !important;
  flex: none !important;
}
</style>
